import { globalObject } from "./globalObject.js";

var atob, btoa;

(function() {

  atob = require("atob");
  btoa = require("btoa");
})();

export { atob, btoa };
